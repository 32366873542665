<template>
  <div class="remarks">
    <lenon-list-page
      title="Report Remarks"
      :columns="columns"
      :rows="selectedRemarks"
      :table-loading="tableLoading"
      :show-create="false"
      sort-column="min_score"
      sort-dir="desc"
    >
      <template
        v-if="selectedRemark"
        slot="table-header"
      >
        <div class="col-12">
          <div class="text-gray">
            <strong>{{ selectedRemark.title }}</strong> only applies to reports for students in classes below
          </div>
        </div>
        <div
          v-for="(schoolClass,index) in selectedRemark.classes"
          :key="index"
          :style="index===0?'margin-left:7px;':''"
          style="padding: 7px 7px;"
        >
          <b-badge variant="light-success">
            {{ schoolClass.name }}
          </b-badge>
        </div>
      </template>
      <template slot="left-header-actions">
        <lenon-select
          v-model="selectedRemarkId"
          :options="remarks"
          label-name="title"
          value-name="id"
          placeholder="Select Remark"
        />
      </template>
      <template slot="right-extra-header-actions">
        <div class="d-flex align-self-center align-items-start mb-2 desktop-only">
          <lenon-button
            icon="SettingsIcon"
            label="Remarks"
            tool-tip-text="Manage Remarks (CRUD)"
            v-responsive="$store.getters['app/largeScreen']"
            @onClick="showRemarkSetupModal()"
          />
        </div>
        <div class="d-flex align-self-center align-items-start mb-2" v-responsive="$store.getters['app/smallScreen']">
          <lenon-dropdown left>
            <lenon-dropdown-item
                icon="SettingsIcon"
                text="Remarks"
                @click="showRemarkSetupModal()"
            />
          </lenon-dropdown>
        </div>
      </template>
      <template #index="{row}">
        {{ row.index + 1 }}
      </template>
    </lenon-list-page>

    <remark-setup
      :modal-opened="remarkSetupModalOpened"
      @modalClosed="closeModal('remarkSetup')"
    />
  </div>
</template>

<script>
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import RemarkSetup from '@/views/admin/academics/report-remarks/RemarkSetup.vue'
import { REMARKS_Q } from '@/graphql/queries'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import { BBadge } from 'bootstrap-vue'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'
import LenonDropdown from '@/lenon/components/LenonDropdown'

export default {
  name: 'RemarkList',
  components: {
    LenonDropdown,
    LenonDropdownItem,
    LenonListPage,
    LenonSelect,
    RemarkSetup,
    LenonButton,
    BBadge,
  },
  mixins: [showToast],
  data() {
    return {
      remarkSetupModalOpened: false,
      tableLoading: false,
      selectedRemarkId: null,
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'min_average',
          label: 'Min Average',
        },
        {
          key: 'max_average',
          label: 'Max Average',
        },
        {
          key: 'comment',
          label: 'Comment',
        },
        {
          key: 'improving',
          label: 'Improving',
        },
        {
          key: 'dis_improving',
          label: 'Not Improving',
        },
      ],
    }
  },
  computed: {
    remarks() {
      return this.$store.getters['reportRemarks/modifiedRemarks']
    },
    selectedRemark() {
      return this.remarks.find(r => +r.id === +this.selectedRemarkId)
    },
    selectedRemarks() {
      const rs = this.selectedRemark?.remarks || []
      return rs.map(r => ({
        ...r,
        improving: r.alternative_remarks.improving || 'No Comment',
        dis_improving: r.alternative_remarks.dis_improving || 'No Comment',
      }))
    },
  },
  mounted() {
    this.fetchRemarks()
  },
  methods: {
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'remarkSetup':
          this.remarkSetupModalOpened = false
          break
      }
    },
    showRemarkSetupModal() {
      this.remarkSetupModalOpened = true
    },
    fetchRemarks() {
      this.$apollo.query({ query: REMARKS_Q }).then(res => {
        this.$store.commit('reportRemarks/setRemarks', res.data.remarks)
      })
    },
  },
}
</script>
