<template>
  <!--    Grading System-->
  <validation-observer
    ref="remarkForm"
    v-slot="{invalid}"
  >
    <lenon-modal
      :title="updateRemarkMode?'Update Remark':'Create Remark'"
      :show="remarkModalOpened"
      extra-classes="grading-system"
      size="md"
      :show-overlay="deletingRemark"
      @onClose="closeRemarkModal()"
    >
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
            v-model="selectedRemarkId"
            placeholder="All Report Remarks"
            :options="remarks"
            label-name="title"
            value-name="id"
            @input="populateReportRemarkForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
            icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteRemark()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            label=""
            tool-tip-text="Clear Form"
            @onClick="resetRemarkForm()"
          />
        </div>
      </b-row>
      <error-display :error="error" />
      <div class="row">
        <div class="col-12">
          <small><b>Report remarks are what you would mostly consider as headmaster's remarks. In summary you enter a
            remark, and then you specify an average range for which this remark should apply.</b></small>
        </div>
      </div>
      <lenon-input
        v-model="remark.title"
        name="title"
        placeholder="Enter title, eg. JHS Headmaster's Remarks"
        rules="required"
        label="Title"
      />
      <div class="mt-2">
        <lenon-multi-select
          v-model="remark.classes"
          name="classes"
          label-name="name"
          value-name="id"
          placeholder="Select Classes"
          :options="filteredClasses"
          rules="required"
          label="Affected Classes"
          @remove="handleRemoveClass"
        />
        <div v-html="missingClassText('report remarks')" />
      </div>
      <div class="my-1">
        <b-form-checkbox
          v-model="allClasses"
          class="mt-1"
        >
          Select All Classes
        </b-form-checkbox>
      </div>
      <div
        v-for="(rm,i) in remark.remarks"
        :key="`remark-${i}`"
        style="margin-bottom: 5px;"
      >
        <div
          class="mt-1"
          style="margin-bottom: 0px !important;"
        >
          <strong>Remark {{ i + 1 }}</strong>
        </div>
        <lenon-input
          v-model="rm.comment"
          name="remark"
          placeholder="Enter remark"
          rules="required"
          label="Remark (Default)"
        />
        <div
          v-if="rm.advanced"
          style="margin-top: 0px !important;"
        >
          <lenon-input
            v-model="rm.alternative_remarks.improving"
            name="improving"
            placeholder="Enter remark"
            rules="required"
            label="Remark (Improving)"
          />
          <div style="margin-top:-10px;">
            <small><b>This will be used in place of default remark if the student is improving.</b></small>
          </div>
          <div
            style="margin-top: 10px !important;"
            class="mb-2"
          >
            <lenon-input
              v-model="rm.alternative_remarks.dis_improving"
              name="not_improving"
              placeholder="Enter remark"
              rules="required"
              label="Remark (Not Improving)"
            />
            <div style="margin-top:-10px;">
              <small><b>This will be used in place of default remark if the student is not improving.</b></small>
            </div>
          </div>
          <div
            style="margin-top: 0px !important;"
            class="mb-2"
          >
            <lenon-input
              v-model="rm.alternative_remarks.improvement_rate"
              name="improvement_rate"
              placeholder="Enter Rate"
              rules="required|numeric"
              type="number"
              label="Improvement Rate (%)"
            />
            <div style="margin-top:-10px;"><small><b>This will be used as the factor to decide if the student is
              improving or not.</b></small></div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-md-3">
            <lenon-input
              v-model="rm.min_average"
              placeholder="Min"
              name="min_average"
              rules="required|numeric"
              type="number"
              :show-errors="false"
              :suffix-icon="null"
              label="Min Average"
            />
          </div>
          <div class="col-md-3">
            <lenon-input
              v-model="rm.max_average"
              placeholder="Max"
              name="max_average"
              rules="required|numeric"
              type="number"
              :show-errors="false"
              :suffix-icon="null"
              label="Max Average"
            />
          </div>
          <div class="col-md-3 mt-1">
            <b-form-checkbox
              v-model="rm.advanced"
            >
              Advanced
            </b-form-checkbox>
          </div>
          <div class="col-md-3 mt-1">
            <div style="display: flex; justify-content: space-between; align-items: center;">
              <lenon-dropdown
                icon="TrashIcon"
              >
                <b-dropdown-item
                  :disabled="remark.remarks.length===1"
                  @click="removeGrade(i)"
                >
                  Yes
                </b-dropdown-item>
                <b-dropdown-divider />
                <b-dropdown-item>
                  No
                </b-dropdown-item>
              </lenon-dropdown>
            </div>

          </div>
        </div>
      </div>
      <div class="float-right mt-2">
        <lenon-button
          variant="outline-primary"
          icon="PlusIcon"
          label=""
          tool-tip-text="Add a remark"
          class=""
          @onClick="addMoreRemarks()"
        />
      </div>
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
            variant="flat-danger"
            icon="XIcon"
            class="mr-1"
            label="Cancel"
            @onClick="closeRemarkModal()"
          />
          <lenon-button
            :label="updateRemarkMode?'Update':'Create'"
            :disabled="invalid"
            :loading="remarkLoading"
            loading-text="Loading..."
            @onClick="updateRemarkMode?updateRemark():createRemark()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>
import {
  BRow, BDropdownItem, BDropdownDivider, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
// eslint-disable-next-line import/extensions
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonMultiSelect from '@/lenon/components/LenonMultiSelect.vue'
import {
  CREATE_REMARK_M,
  DELETE_REMARK_M,
  UPDATE_REMARK_M,
} from '@/graphql/mutations'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import logData from '@/libs/log'
import { missingClassText } from '@/libs/dnd'

export default {
  name: 'RemarkSetup',
  components: {
    ErrorDisplay,
    LenonMultiSelect,
    LenonInput,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
    BFormCheckbox,
  },
  mixins: [showToast],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deletingRemark: false,
      error: {},
      removedClassIds: [],
      missingClassText,
      allClasses: false,
      remarkModalOpened: false,
      updateRemarkMode: false,
      remarkLoading: false,
      selectedRemarkId: null,
      remark: {
        id: null,
        title: null,
        remarks: [{
          comment: null,
          min_average: null,
          max_average: null,
          advanced: false,
          alternative_remarks: {
            improving: null,
            dis_improving: null,
            improvement_rate: null,
          },
        }],
        classes: [],
      },
    }
  },
  computed: {
    remarks() {
      return this.$store.getters['reportRemarks/modifiedRemarks']
    },
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
    selectedRemark() {
      return this.remarks.find(r => +r.id === +this.selectedRemarkId)
    },
    filteredClasses() {
      let selectedClasses = []
      // compile already selected classes
      this.remarks.forEach(gs => {
        selectedClasses = [...selectedClasses, ...gs.classes.map(c => c.id)]
      })
      // return classes not selected yet
      return this.classes.filter(cls => {
        if (this.removedClassIds.includes(cls.id)) {
          return true
        }
        return !selectedClasses.includes(cls.id)
      })
    },
  },
  watch: {
    allClasses(checked) {
      if (checked) {
        this.remark.classes = this.filteredClasses
      } else {
        this.remark.classes = []
      }
    },
    modalOpened(opened) {
      this.remarkModalOpened = opened
    },
  },
  mounted() {
  },
  methods: {
    handleRemoveClass(id) {
      this.removedClassIds.push(id)
    },
    closeRemarkModal() {
      // this.removedClassIds = []
      this.remarkModalOpened = false
      this.$emit('modalClosed')
    },
    resetRemarkForm() {
      this.remark = {
        id: null,
        title: null,
        remarks: [{
          comment: null,
          min_average: null,
          max_average: null,
          advanced: false,
          alternative_remarks: {
            improving: null,
            dis_improving: null,
            improvement_rate: null,
          },
        }],
        classes: [],
      }
      this.selectedRemarkId = null
      this.updateRemarkMode = false
      this.allClasses = false
      this.$refs.remarkForm.reset()
    },
    populateReportRemarkForm(rr) {
      if (this.selectedRemark) {
        this.updateRemarkMode = true
        this.removedClassIds = []
        this.remark = {
          ...this
            .selectedRemark,
        }
        // eslint-disable-next-line no-underscore-dangle
        delete this.remark.__typename
      } else {
        this.resetRemarkForm()
      }
    },
    addMoreRemarks() {
      this.remark.remarks.push({
        comment: null,
        min_average: null,
        max_average: null,
        advanced: false,
        alternative_remarks: {
          improving: null,
          dis_improving: null,
          improvement_rate: null,
        },
      })
    },
    removeGrade(i) {
      this.remark.remarks.splice(i, 1)
    },
    createRemark() {
      this.error = {}
      this.remarkLoading = true
      const remark = {
        ...this.remark,
        classes: this.remark.classes.map(r => r.id),
        remarks: this.remark.remarks.map(r => ({
          ...r,
          min_average: +r.min_average,
          max_average: +r.max_average,
          alternative_remarks: {
            ...r.alternative_remarks,
            improvement_rate: +r.alternative_remarks.improvement_rate,
          },
        })),
      }
      this.$apollo.mutate({
        mutation: CREATE_REMARK_M,
        variables: { input: remark },
      })
        .then(res => {
          this.remarkLoading = false
          this.$store.commit('reportRemarks/addRemark', res.data.createRemark)
          this.showSuccess('Created Successfully')
          this.resetRemarkForm()
        })
        .catch(err => {
          this.remarkLoading = false
          logData(err)
          this.error = err
          this.showError('Something went wrong, please try again')
        })
    },
    deleteRemark() {
      if (!this.selectedRemarkId) {
        this.showInfo('Please select a remark')
        return
      }
      this.deletingRemark = true
      this.$apollo.mutate({
        mutation: DELETE_REMARK_M,
        variables: { id: this.selectedRemarkId },
      })
        .then(() => {
          this.deletingRemark = false
          this.showSuccess('Deleted Successfully')
          this.$store.commit('reportRemarks/removeRemark', this.selectedRemarkId)
          this.selectedRemarkId = null
          this.resetRemarkForm()
        })
        .catch(() => {
          this.deletingRemark = false
        })
    },
    updateRemark() {
      this.error = {}
      if (!this.remark.id) {
        return
      }
      const remark = {
        ...this.remark,
        classes: this.remark.classes.map(r => r.id),
        remarks: this.remark.remarks.map(r => ({
          ...r,
          min_average: +r.min_average,
          max_average: +r.max_average,
          alternative_remarks: {
            ...r.alternative_remarks,
            improvement_rate: +r.alternative_remarks.improvement_rate,
          },
        })),
      }
      this.remarkLoading = true
      this.$apollo.mutate({
        mutation: UPDATE_REMARK_M,
        variables: { input: remark },
      })
        .then(res => {
          this.showSuccess('Updated remark')
          this.remarkLoading = false
          this.$store.commit('reportRemarks/updateRemark', res.data.updateRemark)
        })
        .catch(err => {
          logData(err)
          this.error = err
          this.showError('Failed to update remark')
          this.remarkLoading = false
        })
    },
  },
}
</script>
